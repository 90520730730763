import React from "react";
import "../../views/Login/Login.css"
import {  Box } from '@mui/material';
import LoaderLogo from '../../../images/loader-logo.png'
import LoaderRound from '../../../images/loader-round.svg'


const Loader = ({loader}) => {
 
  return (
    <>
      <Box className={loader?"":"loader-remove"} sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        position: 'fixed',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.9s ease 0s',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '50%',
          height: '100%',
          background: '#fff',
          left: '0',
          transition: 'all 0.9s ease 0s',
          zIndex: '1',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '50%',
          height: '100%',
          background: '#fff',
          right: '0',
          transition: 'all 0.9s ease 0s',
          zIndex: '1',
        },
        '&.loader-remove': {
          opacity: '0',
          visibility: 'hidden',
          '&::before': {
            left: '-50%',
          },
          '&::after': {
            right: '-50%',
          }
        }
      }}>
        <Box sx={{
              width: '225px',
              height: '225px',
              position: 'relative',
              zIndex: '2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& .loader-roudnd': {
                position: 'absolute',
                top: '0',
                width: '100%',
                left: '0',
                animation: 'loading 5s linear infinite',
              }
        }}>
          <img className="loader-logo" src={LoaderLogo} />
          <img className="loader-roudnd" src={LoaderRound} />
        </Box>
      </Box>
    </>

  )
}

export default Loader