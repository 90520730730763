import React, { useEffect, useState } from "react";
import "./EmpLogin.css"
import { Autocomplete, Box, Button, Grid, Icon, Input, InputAdornment, InputLabel, MenuItem, Modal, Pagination, Select, TableSortLabel, TextareaAutosize, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Logo from '../../../images/logo.png'
import LoaderLogo from '../../../images/loader-logo.png'
import LoaderRound from '../../../images/loader-round.svg'
import UserIcon from '../../../images/user-icon.svg'
import LocationIcon from '../../../images/location-icon.svg'
import MailIcon from '../../../images/mail-icon.svg'
import { BorderRight } from "@mui/icons-material";
import {
  Link, useLocation, useNavigate
} from "react-router-dom";
import { URL } from "../../Utils/constant";
import Loader from "../../components/Loader/Loader";
import axios from "../../Utils/axios-config";
const EmpLogin = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [empPIn, setEmpPin] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [franchisesList, setFranchisesList] = useState([])
  const [franchiseDetail, setFranchiseDetail] = useState({})

  useEffect(() => {
    const emptoken = JSON.parse(localStorage.getItem("paymore_emp_id"))?.token
    const userId = JSON.parse(localStorage.getItem("paymore_user_id"))
    if (userId?.userid && emptoken) {
      navigate('/products')
    } else if (emptoken) {
      navigate('/login')
    }
    getAllFrenchiseName()
    setLoader(false)
  }, [])
  const getAllFrenchiseName = async () => {
    try {
      const data = await axios.get(URL.GET_FRENCHISE_NAME)
      const options = []
      for (let ai = 0; ai < data?.data?.length; ai++) {
        let opt = { label: data?.data[ai]?.franchiseName, value: data?.data[ai]?.franchiseName, franchiseId: data?.data[ai]?._id }
        options.push(opt)
      }
      setFranchisesList(options)
    } catch (err) {
      console.log('=====>', err)
    }

  }
  const onLogin = async (e) => {
    e.preventDefault()
    setLoader(true)
    const data = {
      franchiseId: franchiseDetail?.franchiseId,
      pin: empPIn,
      franchiseName:franchiseDetail?.label
    }
    try {
      const checkVerify = await axios.post(URL?.FRENCHISE_LOGIN, data)
      const maindata = {
        franchiseData: checkVerify?.data?.franchiseData,
        token: checkVerify?.data?.token,
      }
      localStorage.setItem("paymore_emp_id", JSON.stringify(maindata))
      navigate('/login')
    } catch (err) {
      console.log('===err==>', err)
      setErrorMsg(err?.response?.data?.error)
      setLoader(false)
    }

  }
  const handleFrenchisesChange = (e, value) => {
    setFranchiseDetail(value)
  }

  return (
    <>
      <Loader loader={loader} />
      <Box className="login-wrap" sx={{
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
      }}>
        <Box sx={{
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          '& a': {
            background: 'transparent',
            border: 'none',
            padding: '0',
            borderBottom: '1px solid #ffffff',
            color: '#ffffff',
            cursor: 'pointer',
            opacity: '0.5',
          }
        }}>
        </Box>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.7)',
          borderRadius: '25px',
          background: 'linear-gradient(90deg, rgba(255,255,255,0.5046612394957983) 0%, rgba(255,255,255,0.20214023109243695) 100%)',
          width: '500px',
          maxWidth: '100%',
          padding: '60px',
        }}>
          <Typography variant="h2" sx={{
            fontSize: '60px',
            fontFamily: '"Nunito Sans", sans-serif',
            color: "#fff",
            marginBottom: '10px',
            '& img': {
              marginTop: '0px',
              display: 'block',
              width: "100%"
            }
          }}>
            Welcome to
            <img src={Logo} />
          </Typography>
          <Box sx={{
            maxWidth: '260px',
            margin: '0px auto 0',
            '& .input-box': {
              position: 'relative',
              '& img': {
                position: 'absolute',
                left: '0',
                top: '9px',
              },
              '& input': {
                border: 'none',
                borderBottom: '1px solid #fff',
                background: 'transparent',
                color: '#ffffff',
                fontSize: '16px',
                width: '100%',
                padding: '10px 0px 10px 27px',
                '&::placeholder': {
                  color: '#ffffff',
                }
              }
            },
            '& .input-box + .input-box': {
              marginTop: '20px',
            }
          }}>
            {console.log('=====>', franchisesList)}
            <form onSubmit={onLogin}>
              <Box className="input-box">
                {/* <img src={MailIcon} />
                <input required placeholder="E-mail / Mobile" onChange={(e) => setEmailMobile(e.target.value)} type="text" /> */}
                <Autocomplete
                  options={franchisesList}
                  getOptionLabel={(option) => option?.label}
                  onChange={handleFrenchisesChange}
                  renderInput={(params) => <TextField {...params} label="Select Franchise"
                    InputLabelProps={{ style: { color: '#d9d9d9' } }}
                    // InputProps={{ disableUnderline: true }}
                    sx={{ '& .MuiOutlinedInput-root': { borderColor: 'green', borderBottom: 'none' } }} // Change select box border color to green

                  />}
                />
              </Box>
              <Box className="input-box">
                <img src={UserIcon} />
                <input className="placeholder-main" required placeholder="Employee pin" onChange={(e) => setEmpPin(e.target.value)} type="number" />

              </Box>
              {errorMsg ? <Box className="input-box" sx={{
                color: "#ff8888"
              }}>
                <p>*{errorMsg}</p>
              </Box> : ""}
              <Box sx={{
                marginTop: '30px',
                '& button': {
                  background: '#64BC46',
                  borderRadius: '100px',
                  border: 'none',
                  width: '100%',
                  fontSize: '16px',
                  textTransform: 'uppercase',
                  fontWeight: '500',
                  color: '#fff',
                  padding: '15px 20px'
                }
              }}>
                <button type="submit">
                  Login
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>

  )
}

export default EmpLogin