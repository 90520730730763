import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Routes from './js/Routes';
// import NavBar from './js/components/NavBar/NavBar';
import { Provider } from 'react-redux'
import store from './js/store/store';
import { Box, Typography } from '@mui/material';


const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Box sx={{
          }}>
            {/* <NavBar /> */}
            <Routes />
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

export default App