import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCodeModal from "../../components/QRcode/QRmodal";
import Logo from '../../../images/logo.png'
import { Box } from "@mui/material";

const QR = () => {
  const params = useParams()

  return (
    <>
      <Box sx={{
        position: 'sticky',
        top: '0',
        background: '#fff',
        padding: '0 20px',
        zIndex: '98',
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '2px solid #96969633',
          padding: '20px 0',
        }}>
          <Box sx={{
            width: '22%',
            '& img': {
              width: '140px',
            }
          }}>
            <img src={Logo} />
          </Box>
        </Box></Box>
      <QRCodeModal isFromPublicPage={true} QRGeneratorSecretsId={params?.userid} />

    </>

  )
}

export default QR