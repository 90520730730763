import React, { useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import { Box, Typography } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SmsIcon from '@mui/icons-material/Sms';
import axios from '../../Utils/axios-config';
import { URL } from '../../Utils/constant';
import loadingIcon from '../../../images/loading-icon.svg';
import {
    useLocation,
} from "react-router-dom";
const QRCodeGenerator = ({ isFromPublicPage, QRGeneratorSecretsId }) => {
    const [isNotEmailAvailable, setIsNotEmailAvailable] = useState(false)
    const [isNotMobileAvailable, setIsNotMobileAvailable] = useState(false)
    const [getMobileId, setMobileId] = useState("")
    const [getEmailId, setEmailId] = useState("")
    const [invalidEmailOrMobile, setInvalidEmailOrMobile] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    const [loading, setLoader] = useState(false)


    // ** Hooks **
    const qrRef = useRef(null);
    const { pathname, search, hash } = useLocation()
    const currentUrl = window.location.origin + "/qr";
    // ** Function Download QR Code**
    const downloadQRCode = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        saveAs(blob, 'qrcode.png');
                    }
                });
            }
        }
    };
    const sendSMSLink = async () => {
        setIsNotEmailAvailable(false)
        const userdetails = JSON.parse(localStorage.getItem("paymore_user_id"))
        const email_mobile = userdetails?.email_mobile
        let mobileno = ""
        if (email_mobile && !email_mobile?.includes("@")) {
            mobileno = email_mobile
        } else if (getMobileId) {
            if (!/^\+?(\d{1,3})?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/i.test(getMobileId)) {
                setInvalidEmailOrMobile("invalid mobile number!")
                return
            }
            mobileno = getMobileId
            setInvalidEmailOrMobile(false)
        } else {
            setIsNotMobileAvailable(true)
            return
        }
        setLoader(true)
        try {
            const data = {
                mobileno: getMobileId,
                qrlink: currentUrl + "/" + userdetails?.userid
            }
            const sendmail = await axios.post(URL.SEND_QR_IN_SMS, data)
            if (!sendmail?.data?.error) {
                setSuccessMsg("SMS sent successfully!")
                setLoader(false)
                setTimeout(() => {
                    setSuccessMsg("")
                    setIsNotMobileAvailable(false)
                }, 3000);
            } else {
                setInvalidEmailOrMobile("Something went wrong!")
                setTimeout(() => {
                    setInvalidEmailOrMobile("")
                }, 3000);
            }

        } catch (err) {
            console.log('=====>', err)
        } finally {
            setLoader(false)
        }
    }
    const sendMailWithQRCode = async () => {
        setIsNotMobileAvailable(false)
        const email_mobile = JSON.parse(localStorage.getItem("paymore_user_id"))?.email_mobile
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        let emailid = email_mobile
                        let base64image = await blobToBase64(blob)
                        if (email_mobile && email_mobile?.includes("@")) {
                            emailid = email_mobile
                        } else if (getEmailId) {
                            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(getEmailId)) {
                                setInvalidEmailOrMobile("invalid email address!")
                                return
                            }
                            emailid = getEmailId
                            setInvalidEmailOrMobile(false)
                        } else {
                            setIsNotEmailAvailable(true)
                            return
                        }

                        setLoader(true)
                        try {
                            const data = {
                                emailid: emailid,
                                qrcode: base64image
                            }
                            const sendmail = await axios.post(URL.SEND_QR_IN_MAIL, data)
                            if (!sendmail?.data?.error) {
                                setSuccessMsg("Mail sent successfully!")
                                setLoader(false)
                                setTimeout(() => {
                                    setSuccessMsg("")
                                    setIsNotEmailAvailable(false)
                                }, 3000);
                            } else {
                                setInvalidEmailOrMobile("Something went wrong!")
                                setTimeout(() => {
                                    setInvalidEmailOrMobile("")
                                }, 3000);
                            }

                        } catch (err) {
                            console.log('=====>', err)
                        } finally {
                            setLoader(false)
                        }
                    } else {
                        setInvalidEmailOrMobile("Something went wrong!")
                        return

                    }
                });
            }
        }

    }
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    return (
        <div style={{ padding: "15px 70px 70px 70px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div ref={qrRef}>
                <QRCode value={`${QRGeneratorSecretsId}`} size={256} />
            </div>
            {(isNotEmailAvailable || isNotMobileAvailable) ? <div>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '15px 0 0 0',
                }}>
                    <Box sx={{
                        width: '100%',
                        // position: 'relative',
                        display: "flex",
                        flexDirection: "",
                        '& input': {
                            width: '100%',
                            background: '#DDDDDD',
                            border: 'none',
                            fontSize: '16px',
                            padding: '12px 11px 12px 13px',
                            borderRadius: '10px 0 0 10px',
                        },
                        '& button': {
                            borderRadius: '0px 10px 10px 0px',
                            padding: '1px',
                            background: "#64BC46",
                            border: 'none',
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            fontWeight: "500",
                            color: "#fff",
                            fontSize: "16px",
                        }
                    }}>
                        {isNotMobileAvailable ? <><input type="Number" onChange={(e) => setMobileId(e.target.value)} placeholder='Enter mobile number' />
                            <button disabled={loading} onClick={() => sendSMSLink()}>
                                {loading ? <img style={{
                                    width: "21px", transition: "all 0.9s ease 0s",
                                    animation: "loading 5s linear infinite"
                                }} src={loadingIcon} />
                                    : "Send"}
                            </button></> : ""}
                        {isNotEmailAvailable ? <><input type="email" onChange={(e) => setEmailId(e.target.value)} placeholder='Enter Email id' />
                            <button disabled={loading} onClick={() => sendMailWithQRCode()}>
                                {loading ? <img style={{
                                    width: "21px", transition: "all 0.9s ease 0s",
                                    animation: "loading 5s linear infinite"
                                }} src={loadingIcon} />
                                    : "Send"}
                            </button></> : ""}
                    </Box>

                </Box >
                {invalidEmailOrMobile ? <Typography sx={{ color: "red" }}>
                    {invalidEmailOrMobile}
                </Typography> : ""}

            </div> : ""
            }

            {!isFromPublicPage ? <div
                style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "25px" }}
            // className='d-flex justify-content-between align-items-center gap-3 mt-3'
            >

                <Box sx={{
                    marginTop: '0',
                    marginRight: '10px',
                    '& button': {
                        background: '#64BC46',
                        borderRadius: '10px',
                        border: 'none',
                        width: '100%',
                        fontSize: '10px',
                        fontWeight: '500',
                        color: '#fff',
                        padding: '6px 10px'
                    }
                }}>
                    <button title='Download QR Code' onClick={() => downloadQRCode()}>
                        <DownloadingIcon />
                    </button>
                </Box>
                <Box sx={{
                    marginTop: '0',
                    marginRight: '10px',
                    '& button': {
                        background: '#64BC46',
                        borderRadius: '10px',
                        border: 'none',
                        width: '100%',
                        fontSize: '10px',
                        fontWeight: '500',
                        color: '#fff',
                        padding: '6px 10px'
                    }
                }}>
                    <button title='Download QR Code' onClick={() => sendMailWithQRCode()}>
                        <ForwardToInboxIcon />
                    </button>
                </Box>
                <Box sx={{
                    marginTop: '0',
                    marginRight: '10px',
                    '& button': {
                        background: '#64BC46',
                        borderRadius: '10px',
                        border: 'none',
                        width: '100%',
                        fontSize: '10px',
                        fontWeight: '500',
                        color: '#fff',
                        padding: '6px 10px'
                    }
                }}>
                    <button title='Print QR Code' onClick={() => sendSMSLink()}>
                        <SmsIcon />
                    </button>
                </Box>
            </div> : ""}
            {successMsg ? <Typography sx={{ paddingTop: "10px", color: "#64BC46" }}>
                {successMsg}
            </Typography> : ""}
        </div >
    );
};

export default QRCodeGenerator;