import React from 'react'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import QRCodeGenerator from './QRCodeGenerator'
import { useLocation } from 'react-router-dom'

const QRCodeModal = ({ isFromPublicPage,QRGeneratorSecretsId }) => {
    let extraStyle={}
    if(isFromPublicPage){
        extraStyle={
            display:"flex",
            justifyContent:"center"
        }
    }
    return (
        <>
            <div className="modal fade" tabIndex={-1} id="Generated_QR_Code">
                <div className="modal-dialog">
                    <div className="modal-content" style={{background: "#fff", borderRadius: "10px"}}>
                        <div className="modal-header">
                            <h5 style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: "#64BC46",
                                paddingTop:"18px",
                                paddingLeft:"18px",
                                ...extraStyle
                            }}>Pay Tab QR Code</h5>
                        </div>
                        <div className="modal-body">
                            <QRCodeGenerator isFromPublicPage={isFromPublicPage} QRGeneratorSecretsId={QRGeneratorSecretsId} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QRCodeModal