import axioss from "axios";

// Function to get the token from cookies
const getToken = () => {
  const token=localStorage.getItem("paymore_emp_id")
  if(token){
    return JSON.parse(token)
  }
  return null
};
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4001/api"

// Create Axios instance
const axios = axioss.create({
  baseURL: API_URL 
});

// Add an interceptor to include the token in the headers
axios.interceptors.request.use(async (config) => {
  const token = getToken()?.token;
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

export default axios;
